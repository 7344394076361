.card {
	// width: 1095px;
	margin: 50px 50px;
	height: 199px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;

	background: rgba(61, 90, 227, 0.14);
	border: 3px solid #3d5ae3;
	border-radius: 10px;
}

.companyLogo {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;

	font-family: "Comfortaa";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 27px;

	width: 120px;
	height: 100px;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	width: 433px;
	// height: 146px;

	font-family: "Comfortaa";

	color: #000000;
}

.btns {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	grid-gap: 40px;

	width: 192px;
	// height: 52px;
}

.primaryText {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 27px;

	margin-bottom: 20px;
}

.secondaryText {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;

	margin-bottom: 2px;
}

.combine {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	margin-bottom: 10px;
}

.ternaryText {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.btn {
	width: 192px;
	height: 52px;

	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 21px;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: -0.015em;

	color: #ffffff;
	background: #3d5ae3;
	border-radius: 6px;

	cursor: pointer;
}
